import React from "react";
import Tracking from "../src/components/tracking/Tracking";
import NewTracking from "../src/components/tracking/VehicleTracking/VehicleTracking";
import gmapTracking from "../src/components/tracking/GmapVehicleTracking/GmapVehicleTracking";
import RouteTracking from "../src/components/tracking/RouteTracking/RouteTracking";
import heliosTracking from "./components/tracking/HeliosTracking/HeliosTracking";
import ShowDetails from "../src/components/tracking/ShowDetails/ShowDetails";
import HeliosHistoryTracking from "../src/components/tracking/HeliosHistoryTracking/HeliosHistoryTracking";
import Pincode from "../src/components/settings/pincode/Pincode";
import CustomerDashboard from "../src/components/settings/customer/CustomerDashboard";
import CustomerForm from "../src/components/settings/customer/CustomerForm";
import LocationDashboard from "../src/components/settings/location/LocationDashboard";
import LocationForm from "../src/components/settings/location/LocationForm";
import RouteDashboard from "../src/components/settings/route/RouteDashboard";
import RouteForm from "../src/components/settings/route/RouteForm";
import NoPermission from "../src/components/shared/NoPermission/NoPermission";
import TrackingContainerPop from "../src/components/TrackingPopup/TrackingContainerPop";
import UserDashboard from "./components/settings/user/UserDashboard";
import UserForm from "./components/settings/user/UserForm";
import ManageFleet from "./components/manage-fleet/map/Managefleet";
import KycDashboard from "./components/manage-fleet/map/KycDashboard";
import ZoneDashboard from "./components/shared-components/zone/ZoneDashboard";
import ZoneForm from "./components/shared-components/zone/ZoneForm";
import Asset from "./components/settings/asset/Asset";
import AssetCreate from "./components/settings/asset/Asset.create";
import CustomerInviteUsers from "../src/components/settings/customer-invite/users";
import ZohoDashboard from "./components/settings/zoho-dashboard/zohoDashboard";
import ZohoForm from "./components/settings/zoho-dashboard/zohoForm";
import FleetDashboard from "./components/hyperlocal/fleet/FleetDashboard";
import MapviewDashboard from "./components/hyperlocal/fleet/MapviewDashboard";
import ZonesDashboard from "./components/hyperlocal/fleet/ZonesDashboard";
import OrderDetails from "./components/hyperlocal/fleet/OrderDetails";
// import Vendor from "./components/settings/vendor/Vendor";
import VendorList from "./components/settings/vendor/Vendor.list";
import VendorCreate from "./components/settings/vendor/Vendor.create";
import PartnersOnboarding from "./components/settings/partners-onboarding/PartnersOnboarding";
import VendorBlock from "./components/settings/vendor/Vendor.block";
import PaymentStatus from "./components/digital-payment/payment-status";
import DigitalPaymentModule from "./components/digital-payment/digital-payment.module";
import ShipperDashboard from "./components/settings/customer/shipper/shipperDashboard";
import CustomConfig from "../src/components/settings/config/config";
import ConfigEditor from "../src/components/settings/config/configEditor";
import Payouts from "./components/digital-payment/payouts/payouts";
// import PayoutsDetails from "./components/digital-payment/payouts/payoutsDetails";
import DownloadFile from "./components/shared-components/download-file";
import VehiclesList from "./components/settings/asset/vehicles";
import CategoryForm from "./components/settings/asset/vehicles/CategoryForm";
import RateCard from "./components/digital-payment/rate-card";
import RateCardCustomConfig from "./components/settings/ratecard/ratecard-config";
import RateCardConfigEditor from "../src/components/settings/ratecard/ratecard-configEditor";
import AtlasHistoryTracking from "../src/components/tracking/AtlasHistoryTracking/AtlasHistoryTracking";
import AtlasTracking from "../src/components/tracking/AtlasTracking/AtlasTracking";
import LoginForm from "./components/authentication/login";
import Login from "./components/authentication/login";
import Settingmodules from "./components/settings/settings-modules";
import ClientOnboardingForm from "./components/settings/client-onboarding/ClientOnboardingForm";
import ClientDashboard from "./components/settings/client-onboarding/ClientDashboard";
import ZoneCreationDashboard from "./components/settings/zone-creation/ZoneCreationDashboard";
import FMLMZoneDashboard from "./components/fm-lm-config/FMLMZoneDashboard";
import FMLMCityDashboard from "./components/fm-lm-config/FMLMCityDashboard";
import FMLMPanIndiaDashboard from "./components/fm-lm-config/FMLMPanIndiaDashboard";
import DefaultFMLMConfigs from "./components/fm-lm-config/DefaultFMLMConfigs";
import FEStatusDashboard from "./components/hyperlocal/fleet/FEStatusDashboard";

const trackingRoutes = [
  {
    path: "/appv2/tracking/popup/:waybillNo?",
    name: "Waybill Tracking",
    component: TrackingContainerPop,
    layout: "BlankLayout",
  },
  {
    path: "/appv2/tracking/dashboard/tracking",
    name: "Tracking",
    component: Tracking,
    layout: "MainLayout",
  },
  {
    path: "/appv2/tracking/dashboard/vehicle-tracking",
    name: "Vehicle Tracking",
    component: NewTracking,
    layout: "MainLayout",
  },
  {
    path: "/appv2/tracking/dashboard/g-map-vehicle-tracking",
    name: "Beecon Vehicle Tracking",
    component: gmapTracking,
    layout: "MainLayout",
  },
  {
    path: "/appv2/tracking/dashboard/route-tracking/:id",
    name: "Route Tracking",
    component: RouteTracking,
    layout: "ToggleSidebarLayout",
  },
  {
    path: "/appv2/tracking/dashboard/atlas-tracking",
    name: "Atlas Tracking",
    component: AtlasTracking,
    layout: "MainLayout",
  },
  {
    path: "/appv2/tracking/dashboard/atlas-history-tracking/:id",
    name: "Atlas History Tracking",
    component: AtlasHistoryTracking,
    layout: "ToggleSidebarLayout",
  },
  {
    path: "/appv2/tracking/dashboard/helios-tracking",
    name: "Helios Tracking",
    component: heliosTracking,
    layout: "MainLayout",
  },
  {
    path: "/appv2/tracking/dashboard/helios-history-tracking/:id",
    name: "Helios History Tracking",
    component: HeliosHistoryTracking,
    layout: "ToggleSidebarLayout",
  },
  {
    path: "/appv2/tracking/dashboard/show-details/:id",
    name: "Show Details",
    component: ShowDetails,
    layout: "ToggleSidebarLayout",
  },
  {
    path: "/appv2/tracking/dashboard",
    name: "Tracking",
    component: Tracking,
    layout: "MainLayout",
  },
];

const settingRoutes = [
  {
    path: "/appv2/settings/dashboard/pincode",
    name: "Pincode",
    component: Pincode,
    layout: "MainLayout",
  },
  {
    path: "/appv2/settings/dashboard/partners-onboarding",
    name: "Partners Onboarding",
    component: PartnersOnboarding,
    layout: "MainLayout",
  },
  {
    path: "/appv2/settings/dashboard/route",
    component: RouteDashboard,
    name: "Settings",
    layout: "MainLayout",
  },
  {
    path: "/appv2/settings/form/route/:id?",
    component: RouteForm,
    name: "Settings",
    layout: "ToggleSidebarLayout",
  },
  {
    path: "/appv2/settings/dashboard/location",
    component: LocationDashboard,
    name: "Settings",
    layout: "MainLayout",
  },
  {
    path: "/appv2/settings/form/location/:id?",
    component: LocationForm,
    name: "Settings",
    layout: "ToggleSidebarLayout",
  },
  {
    path: "/appv2/settings/dashboard/customer/shippers",
    component: ShipperDashboard,
    name: "Settings",
    layout: "MainLayout",
    isReact: true,
  },
  {
    path: "/appv2/settings/dashboard/customer/invite-user",
    component: CustomerInviteUsers,
    name: "Settings",
    layout: "MainLayout",
    isReact: true,
  },
  {
    path: "/appv2/settings/dashboard/customer",
    component: CustomerDashboard,
    name: "Settings",
    layout: "MainLayout",
    isReact: true,
  },
  {
    path: "/appv2/settings/dashboard/custom-config/edit",
    component: ConfigEditor,
    name: "Settings",
    layout: "ToggleSidebarLayout",
    isReact: true,
  },
  {
    path: "/appv2/settings/dashboard/custom-config",
    component: CustomConfig,
    name: "Settings",
    layout: "MainLayout",
    isReact: true,
  },
  {
    path: "/appv2/settings/dashboard/ratecard-config/edit",
    component: RateCardConfigEditor,
    name: "Settings",
    layout: "ToggleSidebarLayout",
    isReact: true,
  },
  {
    path: "/appv2/settings/dashboard/ratecard-config",
    component: RateCardCustomConfig,
    name: "Settings",
    layout: "MainLayout",
    isReact: true,
  },

  {
    path: "/appv2/settings/form/customer/:id?",
    component: CustomerForm,
    name: "Settings",
    layout: "ToggleSidebarLayout",
    isReact: true,
  },

  {
    path: "/appv2/settings/dashboard/zoho-dashboard",
    component: ZohoDashboard,
    name: "Settings",
    layout: "MainLayout",
    isReact: true,
  },
  {
    path: "/appv2/settings/form/zoho-dashboard/:id?",
    component: ZohoForm,
    name: "Settings",
    layout: "MainLayout",
    isReact: true,
  },
  {
    path: "/appv2/settings/dashboard/vendor/blocked/:id",
    component: VendorBlock,
    name: "Vendor",
    layout: "MainLayout",
    isReact: true,
  },
  {
    path: "/appv2/settings/dashboard/vendor",
    component: VendorList,
    name: "Vendor",
    redirect: true,
    layout: "MainLayout",
  },
  {
    path: "/appv2/settings/form/vendor/:id?",
    component: VendorCreate,
    name: "Vendor Management",
    redirect: true,
    layout: "ToggleSidebarLayout",
  },
  {
    path: "/appv2/settings/dashboard/user",
    component: UserDashboard,
    name: "Settings",
    layout: "MainLayout",
    isReact: true,
  },

  {
    path: "/appv2/settings/dashboard/asset/categories",
    component: VehiclesList,
    name: "Asset",
    layout: "MainLayout",
    isReact: true,
  },
  {
    path: "/appv2/settings/form/asset/categories",
    component: CategoryForm,
    name: "Asset",
    layout: "MainLayout",
    isReact: true,
  },
  {
    path: "/appv2/settings/dashboard/asset",
    component: Asset,
    name: "Asset",
    layout: "MainLayout",
    isReact: true,
  },

  {
    path: "/appv2/settings/form/asset",
    component: AssetCreate,
    name: "Asset Create",
    layout: "ToggleSidebarLayout",
    isReact: true,
  },
  {
    path: "/appv2/settings/form/user/:id?",
    component: UserForm,
    name: "Settings",
    layout: "ToggleSidebarLayout",
    isReact: true,
  },
  {
    path: "/appv2/settings/dashboard/zone",
    component: () => <ZoneDashboard parent="settings" />,
    name: "Settings",
    layout: "MainLayout",
    isReact: true,
  },
  {
    path: "/appv2/settings/form/zone",
    component: ZoneForm,
    name: "Settings",
    layout: "ToggleSidebarLayout",
    isReact: true,
  },
  {
    path: "/appv2/settings/dashboard/client-onboarding",
    name: "Settings",
    component: ClientDashboard,
    layout: "MainLayout",
    isReact: true,
  },
  {
    path: "/appv2/settings/form/client-onboarding/:id?",
    component: ClientOnboardingForm,
    name: "Settings",
    layout: "ToggleSidebarLayout",
    isReact: true,
  },
  {
    path: "/appv2/settings/dashboard/zone-creation",
    name: "Settings",
    component: ZoneCreationDashboard,
    layout: "MainLayout",
    isReact: true,
  },
  {
    path: "/appv2/settings/dashboard",
    component: Settingmodules,
    name: "Settings",
    layout: "MainLayout",
  },
];

const loginRoutes = [
  {
    path: "/appv2/login",
    name: "Customer Login",
    component: Login,
    layout: "BlankLayout",
  },
];

const hyperlocalRoutes = [
  {
    path: "/appv2/hyperlocal/dashboard/fleet",
    name: "Fleet",
    component: FleetDashboard,
    layout: "HyperlocalMainLayout",
  },
  {
    path: "/appv2/hyperlocal/dashboard/map-view",
    name: "Fleet",
    component: MapviewDashboard,
    layout: "HyperlocalMainLayout",
  },
  {
    path: "/appv2/hyperlocal/dashboard/zones",
    name: "Zones",
    component: ZonesDashboard,
    layout: "HyperlocalMainLayout",
  },
  {
    path: "/appv2/hyperlocal/dashboard/order-details",
    name: "order-details",
    component: OrderDetails,
    layout: "HyperlocalMainLayout",
  },
  {
    path: "/appv2/hyperlocal/dashboard/staff-status",
    name: "fe-status",
    component: FEStatusDashboard,
    layout: "HyperlocalMainLayout",
  },
  {
    path: "/appv2/hyperlocal/dashboard",
    name: "Fleet",
    component: FleetDashboard,
    layout: "HyperlocalMainLayout",
  },
];

const digitalPaymentRoutes = [
  {
    path: "/appv2/digital-payment/dashboard/payout",
    name: "payouts",
    component: Payouts,
    layout: "BlankLayout",
  },
  {
    path: "/appv2/digital-payment/dashboard/rate-card",
    name: "rate-card",
    component: RateCard,
    layout: "BlankLayout",
  },
  // {
  //     path: "/appv2/digital-payment/payout/:id?",
  //     name: "Payouts Details",
  //     component: PayoutsDetails,
  //     layout: "BlankLayout",
  //     isReact: true
  // },
  {
    path: "/appv2/digital-payment/dashboard/payment-status",
    name: "Payment Status",
    component: PaymentStatus,
    layout: "MainLayout",
    isReact: true,
  },
  {
    path: "/appv2/digital-payment/dashboard",
    name: "Digital Payment",
    component: DigitalPaymentModule,
    layout: "MainLayout",
    isReact: true,
  },
];

const managefleetRoutes = [
  {
    path: "/appv2/manage_fleet/dashboard/live-tracking",
    name: "Manage Fleet",
    component: ManageFleet,
    layout: "MainLayout",
  },

  {
    path: "/appv2/manage_fleet/dashboard/kyc",
    name: "kyc",
    component: KycDashboard,
    layout: "MainLayout",
  },
  {
    path: "/appv2/manage_fleet/dashboard",
    name: "Manage Fleet",
    component: ManageFleet,
    layout: "MainLayout",
  },
];
const fmLmConfigRoutes = [
  {
    path: "/appv2/config/dashboard/country",
    name: "FM LM Config",
    component: FMLMPanIndiaDashboard,
    layout: "MainLayout",
  },
  {
    path: "/appv2/config/dashboard/city",
    name: "FM LM Config",
    component: FMLMCityDashboard,
    layout: "MainLayout",
  },
  {
    path: "/appv2/config/dashboard/zone",
    name: "FM LM Config",
    component: FMLMZoneDashboard,
    layout: "MainLayout",
  },
  {
    path: "/appv2/config/dashboard/default",
    name: "FM LM Config",
    component: DefaultFMLMConfigs,
    layout: "MainLayout",
  },
  {
    path: "/appv2/config/dashboard",
    name: "FM LM Config",
    component: FMLMZoneDashboard,
    layout: "MainLayout",
  },
];

const miscRoutes = [
  {
    path: "/appv2/download",
    component: DownloadFile,
    name: "Download",
    layout: "BlankLayout",
    redirect: true,
    isReact: true,
  },
  {
    path: "/appv2/noPermission/dashboard",
    name: "No Permission",
    component: NoPermission,
    layout: "NosidebarLayout",
  },
];

const routes = [
  ...trackingRoutes,
  ...settingRoutes,
  ...loginRoutes,
  ...hyperlocalRoutes,
  ...digitalPaymentRoutes,
  ...managefleetRoutes,
  ...fmLmConfigRoutes,
  ...miscRoutes,
];

export default routes;
