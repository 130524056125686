import React, { Component } from "react";
import "./styles.scss";
import { Dropdown, Icon, Menu, Spin } from "antd";
import OrderPayIcon from "../../../assets/img/order_pay_icon.svg";
import DayIcon from "../../../assets/img/day_icon.svg";
import WeekIcon from "../../../assets/img/week_icon.svg";
import BonusIcon from "../../../assets/img/bonus_icon.svg";
import WeekBonusIcon from "../../../assets/img/week_bonus_icon.svg";
import WarningIcon from "../../../assets/img/warning.png";
import RateCardService from "../../../services/digital-payment/rate-card.service";

const rateCardService = new RateCardService();

const Icon_Set = Object.freeze({
  order_charges: OrderPayIcon,
  daily_mg: DayIcon,
  weekly_mg: WeekIcon,
  daily_bonus: BonusIcon,
  Weekly_bonus: WeekBonusIcon
});

class RateCard extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      source: !!props?.location?.state?.source,
      loading: false,
      selectedTab: 0,
      rateCard: {}
    };
  }

  componentDidMount() {
    this.fetchRateCard();
  }

  fetchRateCard = () => {
    const self = this;
    this.setState(
      {
        loading: true
      },
      () => {
        rateCardService
          .getUserRateCard()
          .then(resp => {
            self.setState({
              rateCard: resp?.data?.response?.rateCard || {},
              loading: false
            });
          })
          .catch(err => {
            self.setState({
              rateCard: {},
              loading: false
            });
          });
      }
    );
  };

  render() {
    const { selectedTab, rateCard, loading, source } = this.state;
    if (loading) {
      return (
        <div className="rate-card-container">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              height: "100vh",
              padding: "30px"
            }}
          >
            <Spin />
          </div>
        </div>
      );
    }
    return (
      <>
        {!!source && <div className="header"><Icon onClick={()=>this.props.history.back()} style={{marginRight: 15 }} type="arrow-left" />Rate Card</div>}
        <div className="rate-card-container">
          {!!Object.keys(rateCard)?.length ? (
            rateCard.rate_card_components.base_cards.map((item, index) => (
              <div
                key={index}
                className="card"
                onClick={e => this.setState({ selectedTab: index })}
              >
                <div className="card-flex">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center"
                    }}
                  >
                    {item?.name && (
                      <img src={Icon_Set[item.name]} alt={"OrderPayIcon"} />
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        marginLeft: 10
                      }}
                    >
                      <div className="title">{TextRender(item.main_text)}</div>
                      {item?.sub_text && (
                        <div className="description">
                          {TextRender(item.sub_text)}
                        </div>
                      )}
                    </div>
                  </div>
                  <Icon
                    type={index === selectedTab ? "down" : "right"}
                    style={{ width: 24 }}
                  />
                </div>
                {index === selectedTab && (
                  <>
                    {!!item?.list_view?.length && (
                      <ListView data={item?.list_view} />
                    )}
                    {!!item?.combo_list?.length && (
                      <ComboListView data={item?.combo_list} />
                    )}
                    {!!item?.conditions?.length && (
                      <ConditionBlock data={item?.conditions} />
                    )}
                  </>
                )}
              </div>
            ))
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                height: "100vh",
                padding: "30px"
              }}
            >
              <img
                src={WarningIcon}
                alt="WarningIcon"
                style={{ width: "35%", marginBottom: 20 }}
              />
              <h4>Please Contact Support for any Queries on your Rate Card</h4>
            </div>
          )}
        </div>
      </>
    );
  }
}

function ConditionBlock({ data, ...props }) {
  return (
    <div className="card card-conditions">
      <p>Conditions</p>
      {data.map((item, index) => (
        <p key={index}>{TextRender(item?.text)}</p>
      ))}
    </div>
  );
}

function ListView({ data, ...props }) {
  return data.map((item, index) => (
    <div key={index} className="card list-view-card">
      <div className="card-flex">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginLeft: 10
          }}
        >
          <div className="title">{TextRender(item.main_text)}</div>
          {!!item.sub_text && (
            <div className="description">{TextRender(item.sub_text)}</div>
          )}
        </div>
        <div className="title">{TextRender(item.amount)}</div>
      </div>
    </div>
  ));
}
function ComboListView({ data, ...props }) {
  const [selectedCard, setSelectedCard] = React.useState(0);
  const [selectedItem, setSelectedItem] = React.useState(data[0]);

  const menu = (
    <Menu
      onClick={({ item, key, keyPath, domEvent }) => {
        setSelectedCard(Number(key));
        setSelectedItem(data[Number(key)]);
      }}
    >
      {data.map((item, index) => (
        <Menu.Item key={index}>{item.main_text}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu} trigger={["click"]}>
        <div className="card combo-list-view-card">
          <div className="card-flex">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                marginLeft: 10
              }}
            >
              <div className="title" style={{ fontSize: 12, fontWeight: 400 }}>
                {TextRender(selectedItem.main_text)}
              </div>
              {selectedItem.sub_text && (
                <div className="description">
                  {TextRender(selectedItem.sub_text)}
                </div>
              )}
            </div>
            <Icon type={"caret-down"} style={{ width: 24 }} />
          </div>
        </div>
      </Dropdown>

      {data.map(
        (item, index) =>
          selectedCard === index && componentRender(item?.component_type, item)
      )}

      {!!selectedItem?.conditions?.length && (
        <ConditionBlock data={selectedItem?.conditions} />
      )}
    </>
  );
}

function componentRender(type, item) {
  switch (type) {
    case "list_item":
      return <ListView data={[item]} />;
    case "horizontal_stepper":
      return (
        <Timeline
          title={
            item?.main_text
              ? {
                  main_text: item?.main_text,
                  amount: item?.amount
                }
              : false
          }
          data={item?.data}
        />
      );
    default:
      return false;
  }
}

function TextRender(text) {
  return text?.replace("Rs", "₹");
}

function Timeline({ title, data, ...props }) {
  return (
    <>
      {title && <ListView data={[title]} />}
      {!!data?.length && (
        <ul className="timeline" id="timeline">
          {data.map((item, index) => {
            const context = (
              <li key={index} className={`li ${index !== 0 ? "complete" : ""}`}>
                <div className="timestamp">
                  <h4>{TextRender(item.upper_text)}</h4>
                </div>
                <div className="status">
                  <h4> {TextRender(item.lower_text)} </h4>
                </div>
              </li>
            );
            return context;
          })}
        </ul>
      )}
    </>
  );
}

export default RateCard;
